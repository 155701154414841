<template>
  <card class="card-user">
    <p class="card-text"></p>
    <div class="author">
      <div class="block block-one"></div>
      <div class="block block-two"></div>
      <div class="block block-three"></div>
      <div class="block block-four"></div>
      <a href="javascript:void(0)">
        <img class="avatar" :src="this.$auth.user.picture?this.$auth.user.picture:'default-avatar.png'" alt="avatar" />
        <h5 class="title">{{ (storeUserProfile.formattedFullName()) ? (storeUserProfile.formattedFullName()) : this.$auth.user.name }}</h5>
        <h5 class="title"><a href="mailto:${sol.email}">{{storeUserProfile.getEmail()}}</a></h5>
      </a>
      <p class="description">{{storeUserProfile.getJobTitle()}}</p>
    </div>
    <p></p>
    <div class="card-description">
      <p>{{storeUserProfile.getAbout()}}</p>
    </div>

    <div slot="footer" class="button-container">
    </div>
  </card>
</template>
<script>

export default {
  computed: {
    storeState() {
      return this.$store.state;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUserProfile() {
      return this.storeUsers.userProfile;
    },
  },
  mounted() {
    //
  }
};

</script>
<style></style>
