<template>
  <card>
    <h5 slot="header" class="title">Edit Profile</h5>
    <form @submit.prevent="onClickSave">
      <div class="row">
        <div v-if="!mEditUserProfile.company" class="col-md-6">
          <base-input
            type="text"
            label="Organisation"
            :disabled="true"
            placeholder="Not Assigned"
          >
          </base-input>
        </div>
        <div v-else class="col-md-6">
          <base-input
            type="text"
            label="Organisation"
            :disabled="true"
            placeholder="Not Assigned"
            v-model="mEditUserProfile.company"
          >
          </base-input>
        </div>

        <div class="col-md-6">
          <base-input
            type="email"
            :disabled="true"
            label="Email address"
            placeholder="user@email.com"
            v-model="mEditUserProfile.email"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <base-input
            type="text"
            label="First Name"
            placeholder="First Name"
            v-model="mEditUserProfile.firstName"
          >
          </base-input>
        </div>
        <div class="col-md-6">
          <base-input
            type="text"
            label="Last Name"
            placeholder="Last Name"
            v-model="mEditUserProfile.lastName"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input
            type="text"
            label="Job Title"
            placeholder=""
            v-model="mEditUserProfile.jobTitle"
          >
          </base-input>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <base-input label="About Me">
            <textarea
              class="form-control"
              placeholder="About Info"
              v-model="mEditUserProfile.about"
            >
            </textarea>
          </base-input>
        </div>
      </div>

      <base-button native-type="submit" type="primary" class="btn-fill">
        Save
      </base-button>
    </form>
  </card>
</template>
<script>


import model from "src/model";

const LOG_TAG = 'user_card';

export default {
  data() {
    return {
      mEditUserProfile: {
        email: '',
        company: '',
        firstName: '',
        lastName: '',
        jobTitle: '',
        about: '',
        export() {
          let payload = {
            "firstName": this.firstName,
            "lastName": this.lastName,
            "jobTitle": this.jobTitle,
            "about": this.about,
          };
          return payload;
        }
      }
    };
  },
  methods: {
    syncProfile() {
      const vm = this;
      vm.mEditUserProfile.email = vm.storeUserProfile.getEmail();
      vm.mEditUserProfile.company = vm.storeUserProfile.getCompanyName();
      vm.mEditUserProfile.firstName = vm.storeUserProfile.getFirstName();
      vm.mEditUserProfile.lastName = vm.storeUserProfile.getLastName();
      vm.mEditUserProfile.jobTitle = vm.storeUserProfile.getJobTitle();
      vm.mEditUserProfile.about = vm.storeUserProfile.getAbout();
    },
    onClickSave() {
      const vm = this;
      window.bloc.update.updateUserProfile(
        vm.mEditUserProfile.export(),
        (data) => {
          window.store.dispatch('addSuccess', {
            title: 'Success',
            message: 'Profile updated successfully',
            timeout: 3000 }
          )
        },
        (error) => {
          window.store.dispatch('addError', {
            title: 'Error',
            message: model.error(error.error).formattedErrorMessage(),
            timeout: 3000 }
          )
        }
      )
    }
  },
  computed: {
    storeState() {
      return this.$store.state;
    },
    storeUsers() {
      return this.$store.state.users;
    },
    storeUserProfile() {
      return this.storeUsers.userProfile;
    }
  },
  mounted() {
    this.syncProfile();
  },
  watch: {
    'storeUserProfile.data.activeOrganisation'(value) {
      this.syncProfile();
    }
  },
};
</script>
<style></style>
