<template>
  <div class="container container-lg">
    <div class="col-md-6 ml-auto mr-auto">
      <div><h1><br /><br /><br /></h1></div>
      <img src="img/tillered/tillered-logo2.PNG" alt="" />
      <div><h1><br /></h1></div>
    </div>
    <div class="col-md-6 ml-auto mr-auto">
      <div slot="footer" v-if="!$auth.loading">
        <h2>Welcome To Tillered Hub</h2>
        <p>By using our service you agree to our terms and conditions. <br /> Please sign in to continue.</p>
        <br />
        <base-button v-if="!$auth.isAuthenticated" native-type="submit" type="btn btn-round btn-primary" class="mb-3" size="lg" @click="login()" block>
          Sign In
        </base-button>
        <base-button v-if="$auth.isAuthenticated" native-type="submit" type="btn btn-round btn-primary" class="mb-3" size="lg" @click="dashboard()" block>
          See my Dashboard
        </base-button>
        <base-button v-if="$auth.isAuthenticated" native-type="submit" type="btn btn-round btn-primary" class="mb-3" size="lg" @click="logout()" block>
          Sign Out
        </base-button>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "home",
  components: {
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin+"/login"
      });
    },
    dashboard() {
      window.location = "/home/dashboard"
    }
  }
};

</script>
