<template>
  <div class="container-lg">
    <div class="row">
      <div v-for="(alert, idx) in alerts.state.alerts" class="col-md-12 alert-container" :key="idx">
        <base-alert :type="alert.type" :icon="alert.icon" :storeindex="idx" dismissible>
          <strong>{{alert.title}}:</strong> {{alert.message}}
        </base-alert>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8"><edit-profile-form> </edit-profile-form></div>
      <div class="col-md-4"><user-card> </user-card></div>
    </div>
  </div>
</template>

<script>

import EditProfileForm from './UserProfile/TilleredEditUserProfile.vue';
import UserCard from './UserProfile/TilleredUserCard.vue';
import { mapGetters, mapActions } from 'vuex';
import BaseAlert from "@/components/BaseAlert";
import alerts from "@/store/modules/alerts";

export default {
  name: "Profile",
  components: {
    BaseAlert,
    EditProfileForm,
    UserCard
  },
  computed: mapGetters(['allUsers','userProfile']),
  data() {
    return {
      alerts
    };
  },
  created() {},
  mounted() {
    window.bloc.account.syncUserProfile();
  }
}
</script>
