<template>
    <div class="container-sm d-flex flex-column h-100vh">
        <div class="m-auto d-flex flex-column">
            <img src="img/tillered/tillered-logo2.PNG" alt="" class="w-50 mb-5 mx-auto"/>
            <card>
                <h2 class="mb-3">Email Verification</h2>
                <p class="mb-2">
                    Before we start, can you please verify your email. You might have been sent an email to do this, but if
                    not, you can click the button below to send a new one. We appologise for this inconvenience, its just
                    to help prevent spam in our system.
                </p>
                 <p class="my-3">
                    You are currently logged in as: <b><code class="px-2 py-1" style="color: var(--white); background-color: var(--default); border-radius: 5px;">{{ storeUserProfile.email }}</code></b>.
                    If this is not you, please click the button below to change user.
                </p>
                <base-button native-type="submit" type="btn btn-round btn-primary" :disabled="btntxt !== 'Verify Email'" class="mb-3" size="lg" @click="() => verifyEmail()" block>
                    {{btntxt}}
                </base-button>
                <base-button native-type="submit" type="btn btn-round btn-secondary" class="mb-3" size="lg" @click="() => logout()" block>
                    Change User
                </base-button>
            </card>
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            btnText: "Verify Email"
        }
    },
    computed: {
        btntxt() {
            return this.btnText;
        },
        storeUsers() {
            return this.$store.state.users;
        },
        storeUserProfile() {
            return this.storeUsers.userProfile.data;
        },
    },
    methods: {
        verifyEmail() {
            console.log("Verify Email");
            window.webapi.profile.sendVerificationEmail(
                (response) => {
                    this.btnText = "Sent!"
                },
                (error) => {
                    this.btnText = "Failed to Send"
                }
            )
        },
        logout() {
            this.$auth.logout({
                returnTo: window.location.origin + "/login"
            });
        }
    }
}

</script>